.ml-2 {
  margin-left: 20px;
}

.tableHead ::v-deep {
  //margin-bottom: 16px;

  .el-select .el-input {
    width: 120px;
  }
}

.record-table {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  color: #333;

  .el-button--default {
    height: 24px;
    line-height: 24px;
    padding: 0 15px;

  }

  .finished {
    border-color: #09BEE9;
    color: #09BEE9;
    background: rgba(9, 190, 233, 0.1);
  }

  .suspended {
    border-color: #FFAB57;
    color: #FFAB57;
    background: rgba(255, 171, 87, 0.1);
  }

  .approval {
    border-color: #FF7B57;
    color: #FF7B57;
    background: rgba(255, 123, 87, 0.1);
  }

  .el-button--text {
    color: #FFAB57;
  }

  .experiment {
    color: #4896FF;
  }

  .product {
    color: #09BEE9;
  }
}

.detail {
  padding: 16px;
  background: #FFFFFF;
  margin-top: 20px;
  height: 90%;
  overflow-y: scroll;

  .title {
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    padding: 0 0 12px 12px;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 16px;
  }

  .desc {
    line-height: 2;
    font-size: 14px;
    color: #333333;
    text-align: justify;
    margin-bottom: 14px;
    padding-left: 12px;

    span {
      display: inline-block;
      min-width: 200px;
      margin-right: 10px;
    }

  }

  .cageList {
    display: flex;

    span {
      display: inline;
      min-width: auto;
      margin-right: 0;
    }
  }

  .tableMember {
    margin-bottom: 14px;
    margin-left: 12px;
    width: calc(100% - 12px);
    font-size: 14px;
    color: #333;
  }
}

.uploadList {
  margin-bottom: 14px;
  padding-left: 12px;

  p {
    border: 1px solid #4F9AFE;
    background: rgba(79, 154, 254, 0.1);
    height: 32px;
    display: inline-block;
    margin-right: 10px;
    padding: 0 17px;
    line-height: 32px;
    color: #4F9AFE;
    cursor: pointer;
  }

  .el-icon-download {
    margin-left: 10px;
  }
}

.formTit {
  border-bottom: 1px solid rgba(130, 182, 253, 0.5);
  padding: 0 0 12px 12px;
  margin-bottom: 14px;
  color: #4F9AFE;
}

.cageNum ::v-deep {
  margin-bottom: 0;

  .el-form-item__content {
    max-width: 800px;
  }

  .el-input {
    width: 150px;
    //margin-bottom: 10px;
    margin-right: 10px;
  }

  .el-input__inner {
    padding-left: 40px;
  }

  .cuddles {
    float: left;
  }
  .el-input__prefix, .el-input__suffix {
    color: #333333;
  }
}
